<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>회원관리 {{ isNew ? '등록' : '수정' }}</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2>기본 정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>회사<span class="forg"> *</span></label>
            <ul>
              <li>
                <common-multi-drop-down
                    ref="cmpyCdRef"
                    v-if="isNew || (!isNew && !!userInfo.userId)"
                    :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
                    :params="{ searchAllYn : 'Y' }"
                    :data-value-field="'cmpyCd'"
                    :data-text-field="'cmpyNm'"
                    v-model="userInfo.cmpyCd"
                    @change="onChangeCmpyCd"
                >
                </common-multi-drop-down>
              </li>
            </ul>
          </dd>
          <dd v-if="isNew"><label>대표 조회 로케이션<span class="forg"> *</span></label>
            <ul>
              <li>
                <common-multi-drop-down
                    ref="locaCdRef"
                    v-model="userInfo.locaCd"
                    :disabled="!userInfo.cmpyCd"
                    :params="{ searchCmpyCd : userInfo.cmpyCd, searchAllYn : 'Y' }"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/location`"
                    :data-value-field="'locaCd'"
                    :data-text-field="'locaNm'"
                >
                </common-multi-drop-down>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>성명<span class="forg"> *</span></label>
            <ul>
              <li><input
                  ref="userNmRef"
                  class="k-textbox w100"
                  v-model.trim="userInfo.userNm"/>
              </li>
            </ul>
          </dd>
          <dd><label>권한<span class="forg"> *</span></label>
            <ul>
              <li>
                <common-multi-drop-down
                    ref="authCdRef"
                    v-model="userInfo.authCd"
                    :disabled="!userInfo.cmpyCd"
                    :all-params-validator="true"
                    :params="{ searchCmpyCd : userInfo.cmpyCd }"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/auth`"
                    :data-value-field="'authCd'"
                    :data-text-field="'authNm'"
                >
                </common-multi-drop-down>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>ID<span v-if="isNew" class="forg"> *</span></label>
            <ul>
              <li v-if="isNew"><input
                  ref="userIdRef"
                  class="k-textbox w70"
                  v-model.trim="userInfo.userId"/>
                <button
                    class="mid_btn orange ml5"
                    :disabled="isDisabledCheck || !userInfo.userId"
                    @click="checkUserId">중복체크
                </button>
              </li>
              <li v-else>{{userInfo.userId}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>휴대전화번호<span class="forg"> *</span></label>
            <ul>
              <li>
                <input
                    ref="spTelNoRef"
                    class="k-textbox"
                    style="width: 100%"
                    v-model.trim="userInfo.spTelNo"
                    @input="() => {userInfo.spTelNo = userInfo.spTelNo.replace(/[^0-9]/g, '')}"
                    :maxlength="11"
                />
              </li>
            </ul>
          </dd>
          <dd><label>유선번호</label>
            <ul>
              <li>
                <input
                    ref="workTelNoRef"
                    class="k-textbox"
                    style="width: 100%"
                    v-model.trim="userInfo.workTelNo"
                    @input="() => {userInfo.workTelNo = userInfo.workTelNo.replace(/[^0-9]/g, '')}"
                    :maxlength="11"
                />
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>이메일주소<span class="forg"> *</span></label>
            <ul>
              <li>
                <input
                    ref="emailRef"
                    class="k-textbox"
                    style="width: 100%"
                    v-model.trim="userInfo.email"
                    type="email"
                />
              </li>
            </ul>
          </dd>
          <dd><label>직위</label>
            <ul>
              <li>
                <kendo-dropdownlist
                    ref="psnScnRef"
                    v-model.trim="userInfo.psnScn"
                    :data-source="$store.state.codeRequestStore.search.combobox.psnScn.data"
                    :data-text-field="'cdNm'"
                    :data-value-field="'cdId'"
                    :option-label="'선택'"
                    style="width: 100%;"
                >
                </kendo-dropdownlist>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>계정상태</label>
            <ul>
              <li>
                <common-radio-group
                    ref="activeYnRef"
                    :data-items='[{label: "활성화", value: "Y",},{label: "비활성화", value: "N",}]'
                    v-model.trim="userInfo.activeYn"
                ></common-radio-group>
              </li>
            </ul>
          </dd>
          <dd><label>비고</label>
            <ul>
              <li>
                <textarea
                    style="width: 100%;"
                    maxlength="200"
                    v-model="userInfo.contents"
                ></textarea>
              </li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div class="contents_wrap" v-if="!isNew">
      <div class="small_title_wrap">
        <h2>접속 가능 회사 정보
          <button class="mid_btn orange" @click="$refs.searchCompanyWindowRef.kendoWidget().center().open()">회사검색</button>
        </h2>
      </div>
      <common-grid-custom
          ref="userMngGridRef"
          style="cursor: pointer; "
          :class="'mgt20'"
          :sortable="false"
          :scrollable="true"
          :data-source="mngList"
          :columns="mngGridColumns"
          :selectable="'row'"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      >
        <template #locaCd="{rowItem}">
          <span v-if="!!rowItem.data.cmpyCd">
            <kendo-multicolumncombobox
                :ref="'locaCdRef'+rowItem.index"
                v-model="rowItem.data.locaCd"
                :data-source="locationListByCmpyCdMap[rowItem.data.cmpyCd]"
                :data-value-field="'locaCd'"
                :data-text-field="'locaNm'"
            >
            </kendo-multicolumncombobox>
          </span>
        </template>
        <template #deleteBtn="{rowItem}">
          <button
              class="small_btn"
              @click="deleteMngCmpy(rowItem)"
          >삭제
          </button>
        </template>
      </common-grid-custom>
    </div>
    <div class="contents_wrap" v-if="!isNew">
      <div class="small_title_wrap">
        <h2>개인 권한 정보</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>이용약관 및 개인정보 활용 동의 일시</label>
            <ul>
              <li>{{ userInfo.psiAgrDt ? '동의' : '미동의' }}
                <span class="mgl10">{{ userInfo.psiAgrDt }}</span>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>마케팅 동의 일시</label>
            <ul>
              <li>
                e-mail : {{ userInfo.mktEmailAgrDt ? '동의' : '미동의' }}
                <span class="mgl10">{{ userInfo.mktEmailAgrDt }}</span> <br>
                SMS : {{ userInfo.mktSmsAgrDt ? '동의' : '미동의' }}
                <span class="mgl10">{{ userInfo.mktSmsAgrDt }}</span> <br>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>최근 로그인 정보</label>
            <ul>
              <li>{{ userInfo.lastLoginDt ? userInfo.lastLoginDt : '없음' }}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.go(-1)">취소</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="onClickUserInfoSave">저장</button>
        </li>
      </ul>
    </div>

    <kendo-window
        ref="searchCompanyWindowRef"
        :title="'회사검색'"
        :modal="true"
        :width="'700px'"
        style="display:none; min-height: 600px;"
        @onPushCmpy="updateMngCmpyList"
        @open="e=>{this.isWindowOpen = true}"
        @close="e=>{this.isWindowOpen = false}"
    >
      <component :is="isWindowOpen ? 'SearchCompany' : null"
                 :selectable="'multiple row'"
                 :search-all-yn="'Y'"
                 :exist-key='mngList'>
      </component>
    </kendo-window>
  </div>
</template>

<script>

import ApiUtil from '/src/api/api.util'
import ApiConfig from '/src/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import SearchCompany from '/src/components/SearchCompany'
import Constant from '@/common/constant'

export default {
  name      : 'UserManageSave',
  components: {
    SearchCompany,
  },
  computed : {
    isDisabledCheck : function (){
      const vm = this
      return vm.userInfo.userId === vm.initUserId
    }
  },
  watch : {
    mngList : function(newValue, oldValue){
      const vm = this
      EtnersCommonUtil.setRowNumber(newValue)
      newValue.forEach(item=>{
        if(EtnersCommonUtil.isEmpty(vm.locationListByCmpyCdMap[item.cmpyCd])) vm.getLocationListByCmpyCd(item.cmpyCd)
      })
    }
  },
  created () {
    if (this.$route.params.unqUserId) {
      this.isNew = false
      this.getUserManageDetail()
    }
  },
  mounted   : function () {
  },
  methods   : {
    onChangeCmpyCd : function (){
      const vm = this
      vm.userInfo.locaCd = null
      vm.userInfo.authCd = null
      vm.$refs.locaCdRef?.widget().value('')
      vm.$refs.authCdRef?.widget().value('')
    },
    deleteMngCmpy(rowItem) {
      const vm = this
      vm.mngList.splice(rowItem.index,1)
      vm.mngList.forEach((item, i)=>{
        if(!item.locaCd || item.locaCd === ''){
          vm.$refs['locaCdRef'+i].kendoWidget().value('')
        }
      })
    },
    getUserManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/user/${vm.$route.params.unqUserId}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.userInfo = response.data.resultData
              if(vm.userInfo.authCd === Constant.efs.auth.SYSTEM_ADMINISTRATOR){
                kendo.alert('시스템관리자는 수정이 불가합니다.')
                vm.$router.go(-1)
              }
              vm.userInfo.spTelNo = EtnersCommonUtil.extractOnlyNumbers(vm.userInfo.spTelNo)
              vm.initUserId = vm.userInfo.userId
              if(response.data.resultData.mngList?.length > 0){
                response.data.resultData.mngList.forEach(item=>{
                  item.deleteBtn = ''
                })
                vm.mngList = EtnersCommonUtil.setRowNumber(response.data.resultData.mngList)
                vm.mngList.forEach(item=>{
                  vm.getLocationListByCmpyCd(item.cmpyCd)
                })
              }
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    getLocationListByCmpyCd : async function (cmpyCd) {
      const vm = this
      let param = {
        searchCmpyCd: cmpyCd,
        searchAllYn: 'Y'
      }
      await ApiUtil.query(`${ApiConfig.efsDomain}/efs/drop-down/location`, param, false)
      .then(async function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.$set(vm.locationListByCmpyCdMap , cmpyCd, response.data.resultData)
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    updateMngCmpyList : function (newUserData){
      const vm = this
      // 새로운데이터 추가
      newUserData.forEach((item, i)=>{
        let sameItem = vm.mngList.some(sameItem=> sameItem.cmpyCd === item.cmpyCd)
        if(!sameItem){
          vm.mngList.push(item)
        }
      })
      // 팝업에서 삭제한 데이터 목록에서 삭제
      vm.mngList = vm.mngList.reduce((newList, nowItem)=>{
        let sameItem = newUserData.some(sameItem=> sameItem.cmpyCd === nowItem.cmpyCd)
        if(sameItem){newList.push(nowItem)}
        return newList
      }, [])
    },
    onClickUserInfoSave () {
      const vm = this
      if (vm.validator()) {
        kendo.confirm('저장 하시겠습니까?').done(
          function () {
            vm.userManageSave()
          })
      }
    },
    userManageSave() {

      const vm = this

      if(vm.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/user`, vm.userInfo).then(response => {
          if (!response.data?.resultStatus) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'UserManageDetail',
            params: { unqUserId : response.data.resultData }
          })
        })
      }else {
        delete vm.userInfo.mngList
        vm.userInfo.mngUserInfoList =
            vm.mngList.reduce((newData, item) => {
              let newItem = {
                cmpyCd : item.cmpyCd,
                locaCd : item.locaCd
              }
              newData.push(newItem)
              return newData
            }, [])

        ApiUtil.put(`${ApiConfig.efsDomain}/efs/manage/user/${vm.$route.params.unqUserId}`, vm.userInfo).then(response => {
          if (!response.data?.resultStatus) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'UserManageDetail',
            params: { unqUserId : vm.$route.params.unqUserId }
          })
        })
      }
    },
    checkUserId : function (){
      const vm = this
      let param = {
        userId : vm.userInfo.userId,
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/user/duplicate/userId`, param)
      .then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }

        let isPass = response.data.resultData
        if(isPass){
          kendo.alert('입력하신 ID로 등록이 가능합니다.')
          vm.isPassUserId = isPass
          vm.initUserId = vm.userInfo.userId
        }else{
          kendo.alert('중복된 ID가 있습니다.')
          vm.isPassUserId = !isPass
        }
      })
    },
    validator(){
      const vm = this
      if(!vm.userInfo.cmpyCd){
        kendo.alert("회사를 선택해주세요.").bind('close', function() {
          vm.$refs.cmpyCdRef.widget().toggle()
        })
        return
      }
      if(vm.isNew && !vm.userInfo.locaCd){
        kendo.alert("기본 로케이션을 선택해주세요.").bind('close', function() {
          vm.$refs.locaCdRef.widget().toggle()
        })
        return
      }

      if(!vm.userInfo.authCd){
        kendo.alert("권한을 선택해주세요.").bind('close', function() {
          vm.$refs.authCdRef.widget().toggle()
        })
        return
      }
      if(vm.isNew){
        // 중복체크를 안한경우
        if(!vm.isPassUserId){
          kendo.alert("ID의 중복체크를 진행해주세요.").bind('close', function() {
            vm.$refs.userIdRef.focus()
          })
          return
        }
      }
      // 중복체크 후 ID를 다시 바꾼경우
      if(vm.userInfo.userId !== vm.initUserId){
        kendo.alert("ID의 중복체크를 진행해주세요.").bind('close', function() {
          vm.$refs.userIdRef.focus()
        })
        return
      }
      if(!vm.userInfo.userNm){
        kendo.alert("성명을 입력해주세요.").bind('close', function() {
          vm.$refs.userNmRef.focus()
        })
        return
      }
      if(!vm.userInfo.spTelNo){
        kendo.alert("휴대전화번호를 입력해주세요.").bind('close', function() {
          vm.$refs.spTelNoRef.focus()
        })
        return
      }
      if(!vm.userInfo.email){
        kendo.alert("이메일을 입력해주세요.").bind('close', function() {
          vm.$refs.emailRef.widget().focus()
        })
        return
      }

      let isExistMngCmpyCdInList = false
      for(let cmpyItem of vm.mngList){
        if(cmpyItem.cmpyCd === vm.userInfo.cmpyCd) {
          isExistMngCmpyCdInList = true
        }
      }
      if(!vm.isNew && !isExistMngCmpyCdInList){
        kendo.alert("접속 가능 회사 정보에 현재 소속회사가 포함되어야합니다.").bind('close', function() {
          vm.$refs.searchCompanyWindowRef.kendoWidget().center().open()
        })
        return false
      }

      let result = true

      if (!vm.isNew){
        vm.mngList.every((item,i)=>{
          if(!item.cmpyCd){
            kendo.alert("회사정보가 올바르지 않습니다. 제외 후 다시 선택해주세요.")
            result = false
          }
          if(result && !item.locaCd){
            kendo.alert(`${item.cmpyNm} 회사의 기본로케이션을 선택해주세요.`).bind('close', function (){
              vm.$refs[`locaCdRef${i}`].kendoWidget().focus()
              vm.$refs[`locaCdRef${i}`].kendoWidget().toggle()
            })
            result = false
          }
          return result
        })
      }

      return result
    },
  },
  data      : function () {
    const vm = this
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      vm : vm,
      ApiConfig,
      locationListByCmpyCdMap : {},
      isWindowOpen : false,
      isNew : true,
      isPassUserId : false,
      initUserId : '',
      userInfo  : {
        activeYn : 'Y'
      },
      mngList : [],
      rowItem : {
        data : {
          cmpyCd : '',
          locaCd : '',
          deleteBtn : '',
        },
        index : '',
      },
      mngGridColumns : [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'cmpyNm',
          title: '회사명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'cmpyCd',
          title: '회사코드',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'locaCd',
          title: '기본로케이션',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'cmpyGbnNm',
          title: '회사구분',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'corporateRegNo',
          title: '사업자번호',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'deleteBtn',
          title: '제외',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>
